import React, { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import {
  Typography,
  Box,
  TextField,
  IconButton,
  Button,
  Grid,
} from "@mui/material";
import { useSelector, connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import CashSecuredPutsTable from "../components/stock/cashSecuredPutsTable";
import LoadingOverlay from "../components/dialog/loadingOverlay";
import CustomAlert from "../components/customAlert";
import PremiumEncourage from "../components/dialog/premiumEncourage";

import { getCoveredCashPutsPremium } from "../actions/stockAction";
import { getExpirations } from "../actions/stockAction";

const Dashboard = ({
  ATMs,
  OTM5s,
  ITM5s,
  ROIs,
  OTM5ROIs,
  ITM5ROIs,
  annualROIs,
  OTM5annualROIs,
  ITM5annualROIs,
  getCoveredCashPutsPremium,
  expiration_date,
  expirations,
  strikeATMs,
  strikeOTM5s,
  strikeITM5s,
  sharePrices,
}) => {
  const theme = useTheme();
  const [selectedButton, setSelectedButton] = useState("5% ITM");
  const [gotFirstResult, setGotFirstResult] = useState(false);
  const [firstCalculate, setFirstCalculate] = useState(false);
  const [selectedROIs, setSelectedROIs] = useState([]);
  const [selectedAnnualROIs, setSelectedAnnualROIs] = useState([]);
  const [premium, setPremium] = useState([]);
  const [strikePrices, setStrikePrices] = useState([]);
  const [openNoExpirationError, setOpenNoExpirationError] = useState(false);
  const [openPremiumDialog, setOpenPremiumDialog] = useState(false);

  const [maxReturns, setMaxReturns] = useState([]);
  const [maxRisks, setMaxRisks] = useState([]);
  const [maxRORs, setMaxRORs] = useState([]);
  const [annualMaxRORs, setAnnualMaxRORs] = useState([]);

  const [maxReturns1, setMaxReturns1] = useState([]);
  const [maxRisks1, setMaxRisks1] = useState([]);
  const [maxRORs1, setMaxRORs1] = useState([]);
  const [annualMaxRORs1, setAnnualMaxRORs1] = useState([]);

  const [maxReturns2, setMaxReturns2] = useState([]);
  const [maxRisks2, setMaxRisks2] = useState([]);
  const [maxRORs2, setMaxRORs2] = useState([]);
  const [annualMaxRORs2, setAnnualMaxRORs2] = useState([]);

  const [maxReturns3, setMaxReturns3] = useState([]);
  const [maxRisks3, setMaxRisks3] = useState([]);
  const [maxRORs3, setMaxRORs3] = useState([]);
  const [annualMaxRORs3, setAnnualMaxRORs3] = useState([]);

  const service_error = useSelector((state) => state.stock.service_error);
  const server_error = useSelector((state) => state.stock.server_error);
  const symbol_error = useSelector(
    (state) => state.stock.symbol_not_found_error_message
  );
  const option_error = useSelector(
    (state) => state.stock.options_not_found_error
  );

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  let symbolParam = urlParams.get("symbol");

  if (symbolParam === null) {
    symbolParam = "";
  }

  const [symbols, setSymbols] = useState([symbolParam]);

  const getDaysToExpirationDate = () => {
    let daysToExpirationDate = 1;
    // const startDate = new Date(formatDate(expiration_date));
    const startDate = new Date(expiration_date);
    const current = new Date();

    // Calculate the time difference in milliseconds
    const timeDifference = Math.abs(current.getTime() - startDate.getTime());

    // Convert the time difference from milliseconds to days
    daysToExpirationDate = Math.ceil(timeDifference / (1000 * 3600 * 24));

    return daysToExpirationDate;
  };

  // const formatDate = (inputDate) => {
  //   // Split the input date string by "/"
  //   const parts = inputDate.split("/");

  //   // Create a new Date object with the parsed parts
  //   const date = new Date(parts[2], parts[0] - 1, parts[1]);

  //   // Format the date to 'YYYY-MM-DD' format
  //   const formattedDate =
  //     date.getFullYear() +
  //     "-" +
  //     String(date.getMonth() + 1).padStart(2, "0") +
  //     "-" +
  //     String(date.getDate()).padStart(2, "0");

  //   return formattedDate;
  // };

  useEffect(() => {
    if (ATMs.length > 0) {
      setGotFirstResult(true);
    } else {
      setGotFirstResult(false);
    }
  }, [ATMs]);

  // set tab of the coveredCallReturnstable as ATM whenever calculation result is updated
  useEffect(() => {
    setSelectedROIs(ROIs);
    setSelectedAnnualROIs(annualROIs);
    setPremium(ATMs);
    setStrikePrices(strikeATMs);
    handleButtonClick("ATM"); //eslint-disable-next-line

    let risks, RORs, annualMaxRORs, daysToExpirationDate;

    // For ATM

    risks = strikeATMs.map((item, index) => {
      return (item * 100 - ATMs[index]).toFixed(0);
    });

    RORs = ATMs.map((item, index) => {
      return ((item / risks[index]) * 100).toFixed(2);
    });

    // Convert the time difference from milliseconds to days
    daysToExpirationDate = getDaysToExpirationDate();

    annualMaxRORs = RORs.map((item) => {
      return (
        (Math.pow(1 + item / 100, 365 / daysToExpirationDate) - 1) *
        100
      ).toFixed(2);
    });

    setMaxReturns1(ATMs);
    setMaxRisks1(risks);
    setMaxRORs1(RORs);
    setAnnualMaxRORs1(annualMaxRORs);

    setMaxReturns(ATMs);
    setMaxRisks(risks);
    setMaxRORs(RORs);
    setAnnualMaxRORs(annualMaxRORs);

    // For OTM5

    risks = strikeOTM5s.map((item, index) => {
      return (item * 100 - OTM5s[index]).toFixed(0);
    });

    RORs = OTM5s.map((item, index) => {
      return ((item / risks[index]) * 100).toFixed(2);
    });

    // Convert the time difference from milliseconds to days
    daysToExpirationDate = getDaysToExpirationDate();

    annualMaxRORs = RORs.map((item) => {
      return (
        (Math.pow(1 + item / 100, 365 / daysToExpirationDate) - 1) *
        100
      ).toFixed(2);
    });

    setMaxReturns2(ATMs);
    setMaxRisks2(risks);
    setMaxRORs2(RORs);
    setAnnualMaxRORs2(annualMaxRORs);

    // For ITM5

    risks = strikeITM5s.map((item, index) => {
      return (item * 100 - ITM5s[index]).toFixed(0);
    });

    RORs = ITM5s.map((item, index) => {
      return ((item / risks[index]) * 100).toFixed(2);
    });

    // Convert the time difference from milliseconds to days
    daysToExpirationDate = getDaysToExpirationDate();

    annualMaxRORs = RORs.map((item) => {
      return (
        (Math.pow(1 + item / 100, 365 / daysToExpirationDate) - 1) *
        100
      ).toFixed(2);
    });

    setMaxReturns3(ATMs);
    setMaxRisks3(risks);
    setMaxRORs3(RORs);
    setAnnualMaxRORs3(annualMaxRORs);
  }, [ROIs, annualROIs, ATMs, strikeATMs]);

  // add symbols
  const addField = () => {
    if (symbols.length >= 3) {
      setOpenPremiumDialog(true);
      return;
    }

    setSymbols([...symbols, ""]);
  };

  const handleSymbolChange = (e, index) => {
    const input = e.target.value.toUpperCase(); // Convert input text to uppercase
    const onlyUppercase = input.replace(/[^A-Z]/g, ""); // Remove any characters that are not uppercase letters
    const values = [...symbols];
    values[index] = onlyUppercase;
    setSymbols(values);
  };

  const handleCalculate = () => {
    const filteredSymbols = symbols.filter((item) => item !== "");

    setSymbols(filteredSymbols);

    if (expirations.length === 0) {
      setOpenNoExpirationError(true);

      setTimeout(() => {
        setOpenNoExpirationError(false);
      }, 2000);

      return;
    }

    const data = {
      symbols: filteredSymbols,
    };

    setFirstCalculate(true);
    getCoveredCashPutsPremium(data, firstCalculateSuccess);
  };

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);

    switch (buttonName) {
      case "ATM":
        setSelectedROIs(ROIs);
        setSelectedAnnualROIs(annualROIs);
        setStrikePrices(strikeATMs);
        setPremium(ATMs);

        setMaxReturns(maxReturns1);
        setMaxRisks(maxRisks1);
        setMaxRORs(maxRORs1);
        setAnnualMaxRORs(annualMaxRORs1);

        break;

      case "5%OTM":
        setSelectedROIs(OTM5ROIs);
        setSelectedAnnualROIs(OTM5annualROIs);
        setStrikePrices(strikeOTM5s);
        setPremium(OTM5s);

        setMaxReturns(maxReturns2);
        setMaxRisks(maxRisks2);
        setMaxRORs(maxRORs2);
        setAnnualMaxRORs(annualMaxRORs2);

        break;

      case "5%ITM":
        setSelectedROIs(ITM5ROIs);
        setSelectedAnnualROIs(ITM5annualROIs);
        setStrikePrices(strikeITM5s);
        setPremium(ITM5s);

        setMaxReturns(maxReturns3);
        setMaxRisks(maxRisks3);
        setMaxRORs(maxRORs3);
        setAnnualMaxRORs(annualMaxRORs3);

        break;

      default:
        break;
    }
  };

  const firstCalculateSuccess = () => {
    setFirstCalculate(false);
  };

  const handleDeleteSymbol = (index) => {
    const cutSymbols = [...symbols];
    cutSymbols.splice(index, 1);

    setSymbols(cutSymbols);
  };

  const openPremium = () => {
    setOpenPremiumDialog(true);
  };

  return (
    <div
      sx={{
        background: "white !important",
        "& .MuiButton-root": {
          backgroundColor: theme.palette.text.third,
        },
        "& .MuiButton-root:hover": {
          color: "grey",
        },
      }}
    >
      <Helmet>
        <script key="function" id="facebook-pixel-script">{`
                !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '1316474689231501');
                fbq('track', 'PageView');
            `}</script>
        <noscript key="image" id="facebook-pixel-image">
          {`<img
            height="1"
            width="1"
            style="display:none"
            src="https://www.facebook.com/tr?id=1316474689231501&ev=PageView&noscript=1"
          />`}
        </noscript>

        {/* Google analytics */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-50287R64JE"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-50287R64JE');
          `}
        </script>
      </Helmet>
      {/* section 1 */}
      <section>
        <Typography variant="h3" color="text.third" textAlign="center" mx={2} mt={4}>
          Cash-Secured Puts
        </Typography>

        <Typography
          variant="h6"
          color="white"
          textAlign="center"
          mt={3}
          paddingX={2}
        >
          Enter tickers that you would like to own, but perhaps at a lower
          price.
        </Typography>

        <fieldset
          style={{
            width: "40%",
            margin: "auto",
            borderColor: theme.palette.text.third,
            borderRadius: 5,
            marginTop: "3vh",
          }}
        >
          <Box
            textAlign="center"
            sx={{
              width: "80vw",
              "@media (min-width: 600px)": {
                width: "auto", // Set to auto for screens wider than 600px
              },
            }}
          >
            <Typography variant="body1" color="white" mt={1.5}>
              Enter stock ticker symbol
            </Typography>
            {symbols.map((symbol, index) => (
              <Grid container display="flex" alignItems="center" key={index}>
                <Grid item xs={2} sm={3} textAlign="right">
                  <Typography variant="body1" color="white" paddingRight={1}>
                    {index + 1}
                    {"."}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={6}
                  sx={{ "& .MuiInputBase-input": { color: "black" } }}
                >
                  <TextField
                    key={index}
                    value={symbol}
                    autoComplete="off"
                    onChange={(e) => handleSymbolChange(e, index)}
                    variant="outlined"
                    sx={{
                      marginTop: 2,
                      marginBottom: 2,
                      background: "white",
                      borderRadius: "10px",
                    }}
                    inputProps={{
                      autoComplete: "new-email",
                    }}
                    style={{ color: "black !important" }}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  sm={3}
                  sx={{ paddingRight: { xs: 0, md: 3 } }}
                >
                  {index !== 0 && (
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        handleDeleteSymbol(index);
                      }}
                    >
                      <RemoveCircleIcon />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            ))}
            <div>
              <IconButton
                aria-label="add"
                onClick={addField}
                size="large"
                style={{ marginY: 2 }}
              >
                <AddCircleIcon />
              </IconButton>
            </div>
          </Box>
          <Box display="" mx="25%" my="" textAlign="center"></Box>
        </fieldset>
        <Box display="" mx="" my="" textAlign="center">
          <Button
            sx={{
              margin: "auto",
              marginTop: 2,
              backgroundColor: `${theme.palette.text.third} !important`,
              color: "white",
              "&:hover": {
                color: "grey",
              },
            }}
            onClick={handleCalculate}
          >
            Calculate
          </Button>
        </Box>
      </section>

      {/* Section 2 */}
      {gotFirstResult && (
        <section>
          <Box
            display="flex"
            marginTop={3}
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              variant="h4"
              color="text.third"
              textAlign="center"
              marginRight={2}
            >
              Assumptions
            </Typography>
            <img
              src="/info.png"
              width={18}
              alt="info"
              onClick={() => openPremium()}
            ></img>
          </Box>
          <fieldset
            style={{
              width: "40%",
              margin: "auto",
              borderColor: theme.palette.text.third,
              borderRadius: 5,
              marginTop: "3vh",
            }}
          >
            <Box
              textAlign="center"
              sx={{
                width: "80vw",
                "@media (min-width: 600px)": {
                  width: "auto", // Set to auto for screens wider than 600px
                },
              }}
            >
              <ul style={{ textAlign: "left", fontSize: 18 }}>
                <li>Selling Cash-Secured Puts</li>
                <li>5% In The Money (5% ITM)</li>
                <li>At The Money (ATM)</li>
                <li>5% Out of The Money (5% OTM)</li>
                <li>
                  Must have enough cash in trading account to cover "Max Risk"!
                </li>
              </ul>
            </Box>
          </fieldset>

          <Box
            display="flex"
            justifyContent="center"
            mt={3}
            mb={10}
            sx={{
              "& .MuiButton-root": {
                color: "white",
                background: ` ${theme.palette.text.third} !important`,
              },
              "& .MuiButton-root:hover": {
                color: "grey",
              },
            }}
          >
            <Button
              sx={{
                marginRight: 2,
              }}
              onClick={() => openPremium()}
            >
              Select Expiry Date
            </Button>
            <Button onClick={() => openPremium()}>Adjust % OTM</Button>
          </Box>
        </section>
      )}

      {/* Section 3 */}
      {gotFirstResult && (
        <section>
          <Typography
            variant="h4"
            color="text.third"
            textAlign="center"
            mt={5}
            mb={4}
            px={1}
          >
            Cash-Secured Puts Premiums and % Returns{" "}
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mx={2}
            my={2}
            sx={{
              "& .MuiButton-root": {
                backgroundColor: "transparent",
                color: "white",
                border: `1px solid ${theme.palette.text.third}`,
                paddingY: 0,
                paddingX: 2,
                height: 60,
              },
              "& .selected": {
                backgroundColor: `${theme.palette.text.third} !important`,
              },
            }}
            gap={2}
          >
            <Button
              className={selectedButton === "5%ITM" ? "selected" : ""}
              onClick={() => handleButtonClick("5%ITM")}
            >
              5% <br /> ITM
            </Button>
            <Button
              className={selectedButton === "ATM" ? "selected" : ""}
              onClick={() => handleButtonClick("ATM")}
            >
              ATM <br></br> {""}
            </Button>
            <Button
              className={selectedButton === "5%OTM" ? "selected" : ""}
              onClick={() => handleButtonClick("5%OTM")}
            >
              5% <br /> OTM
            </Button>
            <img
              src="/info.png"
              width={18}
              height={18}
              alt="info"
              onClick={() => openPremium()}
            ></img>
          </Box>
          <CashSecuredPutsTable
            symbols={symbols}
            ROIs={selectedROIs}
            annualROIs={selectedAnnualROIs}
            premium={premium}
            strikePrices={strikePrices}
            maxReturns={maxReturns}
            maxRisks={maxRisks}
            maxRORs={maxRORs}
            annualMaxRORs={annualMaxRORs}
          />

          <fieldset
            style={{
              width: "40%",
              margin: "auto",
              borderColor: theme.palette.text.third,
              borderRadius: 5,
              marginTop: "3vh",
            }}
          >
            <Box
              textAlign="left"
              padding={2}
              sx={{
                width: "80vw",
                "@media (min-width: 600px)": {
                  width: "auto", // Set to auto for screens wider than 600px
                },
              }}
            >
              <Typography variant="body1" color="white" textAlign="left">
                Your results:
              </Typography>
              <br></br>
              To build a position in <span style={{ color: theme.palette.text.third }}>
                {symbols[0]}
              </span> at a reduced cost you could
              sell 1 cash-secured put per 100 shares you want to own. <br />
              For example, you can sell a put with{" "}
              <span style={{ color: theme.palette.text.third }}>
                {expiration_date}{" "}
              </span>{" "}
              expiry and{" "}
              <span style={{ color: theme.palette.text.third }}>
                ${strikeATMs[0]}
              </span>{" "}
              strike price. This would generate{" "}
              <span style={{ color: theme.palette.text.third }}>
                ${ATMs[0]}{" "}
              </span>
              in premium income for your portfolio today and therefore reduce
              your cost per share to {" "}
              <span style={{ color: theme.palette.text.third }}>
                {maxRisks1[0] / 100}
              </span>
            </Box>
          </fieldset>

          <Box
            sx={{
              textAlign: "center",
              "& .MuiButton-root": {
                backgroundColor: theme.palette.text.third,
                color: "white",
                marginTop: 2,
              },
              "& .MuiButton-root:hover": {
                color: "grey",
              },
            }}
          >
            <Button onClick={() => openPremium()}>Create Shortlist</Button>
            <br></br>
            <Button onClick={() => openPremium()}>Export Table to PDF</Button>
            <br></br>
            <Button onClick={() => setGotFirstResult(false)}>Back</Button>
          </Box>
        </section>
      )}

      {firstCalculate && (
        <LoadingOverlay text="Calculating..." color="success" />
      )}

      {/* Symbol not found error */}
      <CustomAlert
        openState={symbol_error}
        severity="warning"
        text="Ticker entered does not exist"
      />

      {/* Option not found error */}
      <CustomAlert
        openState={option_error}
        severity="warning"
        text="No options available for ticker!"
      />

      {/* Service not available error */}
      <CustomAlert
        openState={service_error}
        severity="warning"
        text="Some tickers will not work on trading holidays. On the paid version, you can plan your trades also on trading holidays!"
      />

      {/* Server error */}
      <CustomAlert
        openState={server_error}
        severity="warning"
        text="Server Error!"
      />

      {/* Expiration not found error */}
      <CustomAlert
        openState={openNoExpirationError}
        severity="warning"
        text="Please upload expiration dates in the expiration page!"
      />

      <PremiumEncourage
        open={openPremiumDialog}
        onClose={() => setOpenPremiumDialog(false)}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getCoveredCashPutsPremium: (data, callback) =>
    dispatch(getCoveredCashPutsPremium(data, callback)),
  getExpirations: () => dispatch(getExpirations()),
});

const mapStateToProps = (state) => ({
  ATMs: state.stock.ATMs,
  OTM5s: state.stock.OTM5s,
  ITM5s: state.stock.ITM5s,
  strikeATMs: state.stock.strikeATMs,
  strikeOTM5s: state.stock.strikeOTM5s,
  strikeITM5s: state.stock.strikeITM5s,
  ROIs: state.stock.ROIs,
  annualROIs: state.stock.annualROIs,
  OTM5ROIs: state.stock.OTM5ROIs,
  OTM5annualROIs: state.stock.OTM5annualROIs,
  ITM5ROIs: state.stock.ITM5ROIs,
  ITM5annualROIs: state.stock.ITM5annualROIs,
  expiration_date: state.stock.expiration_date,
  expirations: state.stock.expirations,
  sharePrices: state.stock.sharePrices,
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
