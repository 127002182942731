import "./App.css";
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Container } from "@mui/material";

import DrawerAppBar from "./components/layout/navbar";
import Dashboard from "./pages/dashboard";
import Expiration from "./pages/expiration";
import Footer from "./components/layout/footer";
import PrivateRoute from "./components/routes/privateRoute";
import ThemeProvider from "./theme/ThemeProvider";
import Scrollbars from "react-custom-scrollbars-2";
import About from "./pages/about";
import NotFound from "./pages/notFound";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const App = () => {

  return (
    <Router>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Scrollbars>
            <DrawerAppBar />
            <Container
              maxWidth="1536"
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <Routes>
                <Route
                  exact
                  path="/"
                  element={<Navigate to="dashboard" />}
                ></Route>
                <Route exact path="/about" element={<About />}></Route>
                <Route exact path="*" element={<NotFound />}></Route>
                <Route
                  exact
                  path="/dashboard"
                  element={<PrivateRoute component={Dashboard} />}
                ></Route>
                <Route
                  exact
                  path="/expiration"
                  element={<PrivateRoute component={Expiration} />}
                ></Route>
              </Routes>
            </Container>
            <Footer />
          </Scrollbars>
        </LocalizationProvider>
      </ThemeProvider>
    </Router>
  );
};

export default App;
